import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import queryString from "query-string"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const queueId = (query: string) => {
  if (query) {
    // q = queue id
    const { q } = queryString.parse(query)
    console.log(q)
  }
}

const IndexPage = () => {
  const location = useLocation()
  queueId(location.search)

  return (
    <Layout>
      <SEO title="Home" />
      <p>
        Schaffen Sie die Warteschlange ab! Mit unserer App setzen Sie in Minuten
        eine Schnellspur ohne Wartezeiten für Ihren Publikumsverkehr auf.
        Reduzieren Sie Wartezeiten vor und während des Aufenthaltes in Ihrem
        Geschäft auf ein Minimum. Kunden müssen nicht mehr lange warten und auch
        der nächste kommt schneller dran. So steigern Sie sowohl Durch- als auch
        Umsatz Ihres Geschäfts. Kunden und Mitarbeiter werden entlastet und die
        Zufriedenheit steigt. Dabei senken sie das Risiko einer Infektion durch
        das Coronavirus, indem Sie Maßnahmen des "Social Distancing" per App
        unterstützen.
      </p>
      <Image></Image>
      <p>
        Ihr Kunde kann seinen Besuch und sein Anliegen ab sofort per App vorab
        ankündigen und sich einen Platz in der Warteschlange reservieren oder
        einen festen Termin vereinbaren. Er wird über zu erwartende Wartezeiten
        oder frei gewordene Termine auf dem Laufenden gehalten und muss so nicht
        warten da er erst kommt kurz bevor er wirklich dran ist. Sie können sich
        frühzeitig auf seinen Aufenthalt im Geschäft vorbereiten und so auch die
        Wartezeit während des Aufenthalts im Geschäft reduzieren. Sie rufen
        Ihren Kunden dann rechtzeitig per App auf, wenn er dran ist.
      </p>
      <p>
        Sie verlieren keine Kunden mehr, die sonst wegen langer Warteschlangen
        gar nicht erst an Ihrem Geschäft stehen bleiben und bearbeiten Sie zudem
        zügiger, was auch den Stress Ihr Mitarbeiter reduziert und zugleich den
        Umsatz erhöht. Statt in der Warteschlange zu warten oder Zeit mit
        Routinetätigkeiten wie dem Suchen von Produkten oder dem Ausfüllen von
        Formularen zu verschwenden, haben Sie und Ihre Kunden mehr Zeit für das
        Wesentliche und auch dazu sich mit Ihrem Angebot auseinander zu setzen.
        Der Kunde wird geschützt und spart Zeit und Nerven, die Mitarbeiter
        entlastet und Sie machen mehr Umsatz. Worauf warten Sie noch? Stellen
        wir uns nicht länger an!
      </p>

      {/* 
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
      </div> 
      */}
      {/* <button
        style={{
          fontWeight: 600,
          color: "white",
          backgroundColor: "red",
          borderRadius: 10,
          margin: 10,
          padding: "15px 20px",
          border: "none",
        }}
      >
        Anstellen
      </button> */}
      {/*  <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage
